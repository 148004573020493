@import '../../../styles/variables.scss';

.newsletterSubscribe {
    display: flex;
    background-color: $color-brand;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px 16px;
    row-gap: 40px;

    .heading {
        display: flex;
        flex-flow: column;
        row-gap: 40px;
        h2,
        h3 {
            color: $white;
            width: 100%;
            margin: 0;
        }

        h2 {
            font-weight: 600;
        }

        h3 {
            font-size: 1.125rem;
        }
    }

    .form {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        border: 1px solid #fff;
        padding: 32px 22px 22px;
        border-radius: 20px;
        row-gap: 24px;

        input {
            background-color: #fff;
            padding: 16px;
            border-radius: 9px;
            border: none;
            height: 65px;
        }

        button {
            height: 65px;
            border-radius: 9px;
            font-size: 1.125rem;
            font-weight: 600;
        }

        small {
            color: #fff;
            text-align: center;
        }

        p {
            color: #fff;
            text-align: center;

            &.error {
                text-align: left;
                padding: 8px 16px;
                border-radius: 5px;
                color: $color-title-text;
                border: 1px solid $color-error;
                background-color: lighten($color-error, 40);
            }
        }
    }

    .feefo {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        max-width: 280px;
    }

    @include respond-to('small') {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        column-gap: 40px;

        .heading {
            row-gap: 32px;
            grid-row: 1 span 1;
            grid-column: 1 span 1;
            align-self: flex-end;

            h2 {
                font-size: 3rem;
            }
        }

        .form {
            grid-row: 1 / span 2;
            grid-column: 2 / span 1;
        }

        .feefo {
            grid-row: 2 span 1;
            grid-column: 1 span 1;
            align-self: flex-start;
        }
    }

    @include respond-to('medium') {
        grid-template-columns: minmax(auto, 526px) minmax(auto, 526px);
        column-gap: 108px;
        row-gap: 48px;

        .form {
            padding: 28px;
        }
    }
}
