@import '../../../../styles/variables.scss';

.desktopCurrencySelector {
    display: none;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;
    min-width: 90px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid $color-strokes-strong;
    padding: 7px 8px;
    box-shadow: 0px 2px 4px rgba(62, 52, 69, 0.16);
    cursor: pointer;
    background-color: #fff;
    transition: all 0.15s ease;
    font-size: 1.125rem;
    color: $color-title-text;

    .flag {
        display: flex;
        flex: 0 0 28px;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        overflow: hidden;

        > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    > span {
        flex: 1 0 auto;
        text-wrap: nowrap;
    }

    &:hover {
        background-color: $color-background;
    }

    &:active {
        box-shadow: 0px 2px 2px rgba(62, 52, 69, 0.3);
    }

    @include respond-to('medium') {
        display: flex;
    }
}

.popover {
    display: none;
    z-index: 101;
    padding: 8px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 0.1s linear;
    border: 1px solid $color-strokes;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0px 16px 32px -4px rgba(62, 52, 69, 0.1),
        0px 2px 4px 0px rgba(62, 52, 69, 0.25);
    row-gap: 8px;

    .arrow {
        position: absolute;
        display: flex;
        top: calc(-1 * var(--offset));
        width: 0;
        height: 0;
        border-left: var(--offset) solid transparent;
        border-right: var(--offset) solid transparent;
        border-bottom: var(--offset) solid $color-strokes;
        &:before {
            position: relative;
            top: 1px;
            left: calc(2px - var(--offset));

            content: '';
            width: 0;
            height: 0;
            border-left: calc(var(--offset) - 2px) solid transparent;
            border-right: calc(var(--offset) - 2px) solid transparent;
            border-bottom: calc(var(--offset) - 1px) solid #fff;
        }
    }

    .option {
        width: 280px;
    }

    &[data-status='open'] {
        opacity: 1;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        opacity: 0;
    }

    @include respond-to('medium') {
        display: flex;
    }
}
