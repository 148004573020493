@import '../../../../styles/variables.scss';

.tertiaryButton {
    background-color: $tap-action-tertiary;
    color: $white;
    border: none;
    &:hover {
        background-color: lighten($tap-action-tertiary, 3);
    }
    &:active {
        background-color: darken($tap-action-tertiary, 3);
    }
}
