@import '../../../styles/variables.scss';

.popularDestinations {
    margin-top: 20px !important;

    .expandable {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        > h3 {
            border-bottom: none;
            font-size: 16px;
            font-weight: 500;
            height: 35px;
        }

        > div {
            > ul {
                list-style: none;
                padding-left: 10px;
                margin-top: 10px !important;
                margin-bottom: 0;

                > li {
                    padding: 10px 0;
                    color: $tap-action-secondary;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}
