@import '../../../../styles/variables.scss';

.secondaryLoadingButton {
    cursor: wait;
    &:hover {
        background-color: $tap-action-secondary;
    }
    &:active {
        background-color: $tap-action-secondary;
    }
    &:focus {
        outline-style: none;
    }
    .loader {
        background-image: url('../../../../assets/images/loader-white.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 35px;
        height: 35px;
        position: relative;
        display: block;
        margin: 0 auto;
        align-self: center;
    }
}
