@import '../../../../../styles/variables.scss';

.searchOption {
    display: flex;
    width: 100%;

    > button {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        column-gap: 16px;
        min-height: 40px;
        border-radius: 4px;
        padding: 0 4px;
        border: none;
        cursor: pointer;
        transition: all 0.1s linear;

        > svg {
            flex: 0 0 20px;
            height: 20px;
            fill: $color-brand;
        }

        > span {
            flex: 1 1 100%;
            font-size: 1.125rem;
            color: $color-title-text;
            text-align: left;
        }

        &:hover {
            background-color: $color-background;
        }
        &:active {
            background-color: $color-active-background;
        }
    }
}
