@import './styles/variables.scss';

body.mobile-menu-active {
    overflow: hidden;
    position: fixed;
    width: 100%;
    @include respond-to('small') {
        overflow: unset;
        position: unset;
        width: unset;
    }
}

.main {
    position: relative;
    display: block;
    min-height: calc(100vh + 70px);
}

.container {
    max-width: 1154px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
}
.gif-loader {
    background-image: url('./assets/images/loader.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 80px;
    height: 80px;
    position: relative;
    display: block;
    margin: 0 auto;
    align-self: center;
}

/*mobile breakpoints*/
@media only screen and (max-width: 1200px) {
    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@media only screen and (max-width: 768px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .gif-loader {
        align-self: flex-start;
    }
}
