@import '../../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    align-items: center;

    &.disabled {
        .checkbox {
            background: $disabled-grey;
            cursor: not-allowed;

            &:hover {
                border-color: $disabled-grey;
            }
        }

        .label {
            color: $light-grey;
        }
    }

    .label {
        padding-left: 15px;

        span {
            padding-left: 3px;
            color: $amber-warning;
        }
    }

    input[type='checkbox'] {
        display: none;
        width: 0;
    }

    .checkbox {
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        background: $white;
        border-radius: 2px;
        width: 28px;
        height: 28px;
        border: solid 1px $light-grey;
        transition: border-color linear 0.1s;
        flex-shrink: 0;

        &:hover {
            border-color: $bodytext-darkgrey;
        }
        &:active {
            border-color: $tap-action-secondary;
        }

        &:after {
            top: 3px;
            left: 8px;
            width: 6px;
            height: 12px;
            border: solid $bodytext-black;
            border-width: 0 4px 4px 0;
            box-sizing: unset;
            content: '';
            position: absolute;
            transform: rotate(45deg);
            display: none;
        }
    }

    input[type='checkbox']:checked + .checkbox:after {
        display: block;
    }

    &.small {
        min-height: 30px;

        .checkbox {
            width: 22px;
            height: 22px;

            &:after {
                top: 2px;
                left: 5px;
                width: 5px;
                height: 10px;
            }
        }
    }
}
