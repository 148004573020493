@import '../../../../styles/variables.scss';

.blueWireIconButton {
    background-color: $white;
    color: $tap-action-secondary;
    border-color: $tap-action-secondary;
    &:hover {
        background-color: $very-light-grey;
    }
    &:active {
        background-color: $background-blue;
    }
}
