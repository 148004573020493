@import '../../../../styles/variables.scss';

.mobileMenuButton {
    display: flex;
    border: none;
    padding: 0;

    > svg {
        display: flex;
        width: 20px;
        height: 24px;
    }

    @include respond-to('medium') {
        display: none;
    }
}
