@import '../../../styles/variables.scss';

.cookieBannerPage {
    width: 100vw;
    height: 100vh;
    z-index: 2147483639;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: #00000085;
    border-radius: 2px;

    &.hideCookieBanner {
        display: none;
    }

    .cookieBanner {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: calc(100% - 32px);
        visibility: visible;
        z-index: 2147483640;
        bottom: 25px;
        max-width: 100%;
        max-height: calc(100% - 0px);
        overflow: hidden;
        border-radius: 2px;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: white;
        padding-bottom: 10px;
        align-items: center;
        left: 50%;
        padding: 10px;

        transform: translateX(-50%);

        &.hideCookieBanner {
            display: none;
        }

        .cookiesMessage {
            margin-left: 10px;
            margin-right: 10px;
        }

        .bannerButtons {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;

            .acceptCookiesBtn {
                margin-bottom: 10px;
            }
        }
    }

    @include respond-to('small') {
        .cookieBanner {
            flex-flow: row nowrap;
            align-items: center;
            align-self: center;
            max-width: 840px;

            .bannerButtons {
                max-width: 250px;
            }
        }
    }
}

body {
    &.isCookieOpen {
        overflow: hidden;
        padding-right: 15px; /* Avoid width reflow */

        :global(#chat-widget-container),
        :global(#responseiq-app-container) {
            display: none;
        }
    }
}
