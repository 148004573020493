@import '../variables.scss';

.react-select {
    min-height: 40px;
    width: 100%;

    .react-select__control {
        min-height: 40px;
        border: 1px solid #c6c6c6;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.8) !important;

        &--is-focused {
            .react-select__placeholder {
                display: none;
            }
        }

        &:hover {
            border-color: #c6c6c6;
        }
    }

    .react-select__value-container {
        min-height: 38px;
        padding: 5px 8px 5px 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
    }

    .react-select__placeholder {
        padding-left: 0;
        margin-left: 0;
        color: $placeholdertext-grey;
    }

    .react-select__single-value {
        color: #484545;
        margin: 0;
    }

    .react-select__multi-value {
        border-radius: 3px;
        overflow: hidden;

        .react-select__multi-value__label {
            padding: 5px 12px;
            background-color: $color-secondary-action-light;
            font-weight: 500;
            font-size: 14px;
            color: #000;
            border-radius: 0;
        }

        .react-select__multi-value__remove {
            width: 35px;
            background-color: $color-secondary-action;
            text-align: center;
            border-radius: 0;
            transition: background-color 0.15s ease;

            &:hover {
                background-color: $color-secondary-action-dark;
            }

            cursor: pointer;
            svg {
                stroke-width: 0;
                display: block;
                width: 20px;
                height: 20px;
                margin: 0 auto;
                fill: $color-title-text;
            }
        }
    }

    .react-select__indicators {
        cursor: pointer;
        max-height: 57px;
    }

    .react-select__clear-indicator {
        display: none;
    }

    .react-select__dropdown-indicator {
        background-image: url('../../assets/images/icons/chevron.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 22px;
        height: 12px;
        padding: 0;
        object-fit: contain;
        margin-right: 8px;

        svg {
            display: none;
        }
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__menu {
        margin-top: 0;
        padding: 0 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: none;
        color: $color-title-text;
        z-index: 10;

        .react-select__option {
            cursor: pointer;

            &--is-focused {
                background-color: $color-secondary-action-light;
            }

            &--is-selected {
                background-color: $color-secondary-action;
                color: $white;
            }
        }
    }

    &.error {
        .react-select__control {
            border-color: #d0021b;
        }
    }
}
