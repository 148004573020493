@import '../../../../../styles/variables.scss';

.desktopLink {
    display: flex;
    .link {
        display: flex;
        border: none;
        padding: 0 12px;
        color: $color-title-text;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
