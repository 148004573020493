@import '../../../../styles/variables.scss';

.mobileSearchMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;

    .icon {
        stroke: $tap-primary-color;
        height: 100%;
    }
}
