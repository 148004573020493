@import '../../../styles/variables.scss';

.cookieManagerPage {
    width: 100%;
    height: 100%;
    z-index: 2147483640;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #00000085;
    border-radius: 2px;

    &.hideCookieManager {
        display: none;
    }

    .cookieManager {
        width: 90%;
        height: fit-content;
        visibility: visible;
        background-color: white;
        margin: 50px 0px;
        padding: 25px;

        .h3,
        h4 {
            border-bottom: #00000085 1px solid;
        }

        .essentialCookies {
            display: flex;
            justify-content: space-between;
        }

        .functionalCookies {
            display: flex;
            justify-content: space-between;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 20px;

            @include respond-to('small') {
                flex-direction: row;
            }

            .closeButton {
                margin-bottom: 10px;

                @include respond-to('small') {
                    max-width: 250px;
                }
            }

            .saveButton {
                margin-bottom: 10px;

                @include respond-to('small') {
                    max-width: 250px;
                }
            }
        }
    }
}
