@import '../../../../../../../../styles/variables.scss';

.subMenuLink {
    display: flex;
    a {
        padding-left: 2px;
        text-decoration: none;
        color: $color-title-text;
        &:hover {
            text-decoration: underline;
        }
    }

    &.isCurrentPage {
        a {
            color: $color-brand;
            font-weight: 500;
            text-decoration: none;
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
