@import '../../../styles/variables.scss';

$mobileHeight: var(--mobile-height);
$desktopHeight: var(--desktop-height);

.header {
    display: flex;
    position: relative;
    width: 100%;
    height: $mobileHeight;
    border-bottom: 2px solid $color-title-text;
    .inner {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        column-gap: 20px;
        padding-left: 20px;
        padding-right: 20px;

        .home {
            display: flex;
            flex: 1 1 auto;
            max-width: 251px;
            text-decoration: none;
        }

        .buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            column-gap: 6px;
            flex: 0 0 auto;

            .mobileMenuButton {
                margin-left: 10px;
            }
        }
    }

    @include respond-to('medium') {
        height: $desktopHeight;
        border-bottom: none;

        .inner {
            justify-content: flex-start;
            width: calc(100% - 40px);
            max-width: 1160px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;

            .home {
                flex: 1 1 auto;
                min-width: 210px;
            }

            .desktopSearchTool {
                flex: 0 1 auto;
                min-width: 168px;
                max-width: 208px;
            }

            .desktopLinks {
                margin-left: auto;
                flex: 0 0 auto;
            }

            .buttons {
                margin-left: auto;
                flex: 0 0 auto;
            }
        }
    }
}
