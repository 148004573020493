@import '../../../../styles/variables.scss';

.primaryInverseButton {
    background-color: $white;
    color: $tap-action-primary;
    border: 1px solid $tap-action-primary;
    &:hover {
        background-color: lighten($white, 3);
    }
    &:active {
        background-color: darken($white, 3);
    }

    .icon {
        margin-left: 10px;
    }
}
