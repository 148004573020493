@import '../../../styles/variables.scss';

.expandableSection {
    display: flex;
    flex-direction: column;

    .header {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $light-grey;
        cursor: pointer;
        transition: background-color 0.1s linear;
        -webkit-tap-highlight-color: transparent;

        &:active {
            background-color: #eee;
        }

        .button {
            transition: all 0.2s ease-in-out;
            transform: rotate(0);
            margin-right: 5px;
        }
        svg {
            margin-left: 20px;
        }
    }

    .content {
        max-height: 0;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        transition: all 0.3s ease-in;

        > *:first-child {
            margin-top: 20px;
        }
    }

    &.isOpen {
        > .header {
            .button {
                transform: rotate(180deg);
                margin-right: 5px;
            }
        }

        > .content {
            max-height: 5000px;
        }
    }
}
