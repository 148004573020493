@import '../../../../styles/variables.scss';

.loader {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $bodytext-black;
}

.mobileCurrencySelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .currencyLabel {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $bodytext-black;
        flex: 0;
    }

    .currencySelect {
        flex: 1;
        width: inherit;
        outline: none;
        padding: 0 0 0 10px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
        align-content: center;
        box-sizing: border-box;
        border-radius: 3px;
        color: $bodytext-black;
        background-color: $white;
        border: solid 1px $light-grey;
        height: 40px;
        transition: border-color linear 0.1s;
        flex-grow: 1;

        &:hover {
            border-color: $bodytext-darkgrey;
        }
        &:focus {
            border-color: $tap-action-secondary;
        }
    }

    .currencyFlag {
        flex: 0;
        height: 40px;
        img {
            height: 40px;
            width: 40px;
        }
    }
}
