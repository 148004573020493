.wrapper {
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
    }
    h4 {
        margin-bottom: 0;
        margin-top: 15px;
    }
    li {
        list-style: none;
        margin: 0 5px;
        padding: 0;
        width: 100%;
        max-width: 150px;
        display: flex;
        flex-flow: column;
        align-items: center;

        img {
            width: 100%;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
        }
    }
}
