@import '../../../../../../../styles/variables.scss';

.subMenu {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 20px;

    .content {
        display: flex;
        flex: 1 1 auto;
        flex-flow: column nowrap;

        > h3 {
            margin: 0 0 20px 0;
            display: flex;
            flex-flow: column nowrap;
            row-gap: 12px;
            font-size: 1.5rem;
            font-weight: 500;
        }

        .links {
            display: flex;
            flex-flow: column wrap;
            margin: 0 0 20px 0;
            padding: 0;
            list-style: none;
            row-gap: 4px;
            column-gap: 4px;
            max-height: 300px;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .viewAllLink {
            margin-top: auto;
            color: $color-title-text;
        }
    }

    > picture {
        display: flex;
        width: 280px;
        height: 360px;
        flex: 0 0 280px;
        border-radius: 10px;
        overflow: hidden;
        background-color: $color-background;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
