$time: 1.2s;

@keyframes spin-jump {
    0% {
        transform: translateY(0) rotateY(0deg);
    }
    50% {
        transform: translateY(-15px) rotateY(180deg);
    }
    100% {
        transform: translateY(0) rotateY(360deg);
    }
}

@keyframes shrink {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
        opacity: 0.8;
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.loadingLogo {
    width: 80px;
    height: 80px;
    text-align: center;
    img {
        transform: translateZ(0);
        will-change: transform;
        animation: spin-jump $time ease-in-out infinite;
        width: 100%;
        height: 100%;
    }
    &::after {
        transform: translateZ(0);
        will-change: transform;
        content: ' ';
        display: inline-block;
        height: 8px;
        width: 60px;

        background-image: radial-gradient(closest-side, #ccc, transparent 100%);
        animation: shrink $time ease-in-out infinite;
    }
}
