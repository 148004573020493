@import '../../../styles/variables.scss';

.loadingText {
    font-size: 20px;
    color: $bodytext-darkgrey;
    position: relative;

    .dots {
        position: absolute;
        left: 100%;
    }
}
