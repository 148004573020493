@import '../../../../styles/variables.scss';

.tertiaryInverseButton {
    background-color: $white;
    color: $tap-action-tertiary;
    border: 1px solid $tap-action-tertiary;
    &:hover {
        background-color: lighten($white, 3);
    }
    &:active {
        background-color: darken($white, 3);
    }
}
