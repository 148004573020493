.translucentWhiteButton {
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    border: none;

    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
    &:active {
        background-color: rgba(255, 255, 255, 0.95);
    }
}
