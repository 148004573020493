@import '../../../styles/variables.scss';
.memberships {
    display: flex;
    width: 100%;
    max-width: 1160px;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @include respond-to('small') {
        flex-wrap: unset;
    }

    justify-content: center;
    padding: 0 20px;
    margin: 20px auto 0;
    list-style: none;
    border-bottom: 1px solid #eee;

    .membership {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        @media only screen and (min-width: 768px) {
            width: 20%;
            flex-wrap: wrap;
        }

        > img,
        a {
            width: 90%;
            max-width: 150px;
            max-height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                max-height: 75px;
            }
        }
    }
}
