.asyncLoader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    h2 {
        text-align: center;
        max-width: 900px;
    }

    button {
        font-size: 20px;
        max-width: 250px;
    }
}
