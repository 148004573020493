@import '../../styles/variables.scss';

footer {
    background-color: $background-green;
    margin-bottom: 2em;
    padding-bottom: 60px;
    overflow: hidden;

    .contactUs {
        width: calc(100% - 40px);
        margin: 20px auto;
    }

    .linksContent {
        width: calc(100% - 40px);
        margin: 20px auto;

        .expandable {
            &.tradeAgentSignUp {
                > h4 {
                    background-color: lighten($tap-action-secondary, 35%);
                }
                > div {
                    background-color: lighten($tap-action-secondary, 40%);
                }
            }

            > h4 {
                border-bottom-width: 2px;
                padding-left: 5px;
            }

            > div {
                > ul {
                    list-style: none;
                    padding-left: 20px;
                    margin-top: 0;

                    > li {
                        padding: 10px 0;
                        color: $tap-action-secondary;
                        font-weight: 500;

                        a {
                            display: block;
                            text-decoration: none;
                            color: $tap-action-secondary;
                        }
                    }
                }
            }
        }

        .openingTimes {
            > h4 {
                border-bottom: none;
                margin: 20px 0;
            }

            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                > ul {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    padding-left: 0;
                    margin-top: 0;

                    &.times {
                        font-weight: 500;
                    }

                    > li {
                        padding: 10px 0;
                    }
                }
            }
        }
    }

    .footerCopyrightContainer {
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
        margin: 20px auto;

        .footerCopyright {
            min-width: 180px;
            line-height: 20px;
            list-style: none;
            padding: 0 10px 0 0;
        }
        .currencyButton {
            color: $white;
        }
    }

    .talkToUs {
        width: calc(100% - 40px);
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin: 20px auto;

        .talkToUsToby {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                border-radius: 50%;
                max-width: 75%;
                margin-top: 0px;
            }
        }
        .talkToUsDetails {
            small {
                display: block;
                margin: 5px 0;
            }
            a {
                color: $tap-action-secondary;
                text-decoration: none;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    footer {
        .talkToUs {
            display: none;
        }

        .linksContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > div {
                flex-grow: 1;
                margin: 0 20px;
            }
        }

        .footerCopyrightContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media only screen and (min-width: 1200px) {
    footer {
        > div {
            max-width: 1200px;

            &.linksContent {
                > div {
                    &:first-child {
                        width: 50%;
                    }
                }
            }
        }
    }
}
