@import './styles/variables.scss';

body,
html {
    font-family: 'Poppins', sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
    color: $color-body-text;
    box-sizing: border-box;
}

html {
    width: 100%;
    font-size: 16px;
    tab-size: 4;
}
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    position: relative;
    line-height: 1.4;
}

*,
:after,
:before {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: $color-title-text;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dl,
dt,
blockquote {
    overflow-wrap: break-word;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}

textarea {
    resize: vertical;
}

table {
    border-collapse: collapse;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

button,
input,
select,
textarea {
    background-color: transparent;
    border: 1px solid WindowFrame;
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    padding: 0.25em 0.375em;
}

h1 {
    font-size: 2rem;
    font-style: normal;
}

h2 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
}

h3 {
    font-size: 1.25rem;
    font-style: normal;
}

h4 {
    font-size: 1.125rem;
    font-style: normal;
}

h5 {
    font-weight: 500;
    font-style: normal;
}

small {
    font-size: 0.8rem;
    font-style: normal;
}

p {
    a {
        color: $color-secondary-action;
    }
}

a {
    color: $color-body-text;
}

b,
strong,
th {
    font-weight: 500;
}

.large {
    font-size: 3.052rem;
    font-style: normal;
}

.xlarge {
    font-size: 3.815rem;
    font-style: normal;
}

.tiny {
    font-size: 0.625rem;
    font-style: normal;
}

@include respond-to('large') {
    h1 {
        font-size: 2.625rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.563rem;
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1.125rem;
    }
}
