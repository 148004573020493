@import '../../styles/variables.scss';

.salesHeader {
    width: 100%;
    background-color: $tap-action-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    z-index: 15;
    position: relative;
    min-height: 40px;

    .text {
        color: $white;
        text-decoration: none;
        margin: 0;
        padding: 0;

        &a {
            cursor: pointer;
        }
    }
}
