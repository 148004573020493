.pageLoading {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 1025px) {
        height: calc(100vh - 104px);
    }

    .spinner {
        margin-top: -200px;
    }

    .loadingText {
        margin-top: 40px;
    }
}
