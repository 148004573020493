@import '../../../../styles/variables.scss';

.headerMenu {
    overflow-y: scroll;
    padding: 10px 10px 100px;
    height: calc(100vh - 68px);
    position: fixed;
    top: 70px;
    bottom: 0;
    width: 100%;
    z-index: 200;
    right: -100%;
    background-color: $white;
    transition: right ease-in-out 0.2s;

    .wrapper {
        height: 100%;

        .hide {
            display: none;
        }

        .mobileCurrencySelector {
            padding: 0 20px 9px;
            width: 100%;
            border-bottom: 1px solid $light-grey;
            height: 50px;
            display: flex;
            justify-content: stretch;
        }

        .menuItems {
            list-style: none;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            padding-bottom: 200px;
            user-select: none;

            .menuItem {
                svg {
                    margin-right: 5px;
                }
            }

            .link {
                .icon {
                    margin-left: 5px;
                    height: 25px;
                }
            }

            .enquiryNumbers {
                margin-top: 6px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .currencySelector {
            display: none;
        }
    }

    &.saleBannerEnabled {
        top: 110px;
    }

    &.isActive {
        right: 0;
        background-color: $white;
        .desktop {
            display: flex;
        }
    }

    @include respond-to('small') {
        .wrapper {
            .tradeSignIn,
            .tradeSignup {
                display: none;
            }

            .menuItems {
                .enquiryNumbers {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    @media (min-width: 930px) {
        .wrapper {
            .tradeSignIn,
            .tradeSignup {
                display: none;
            }

            .menuItems {
                .menuItem {
                    > *:first-child {
                        font-size: 16px;
                        padding: 0 5px;
                    }
                }

                .enquiryNumbers {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }

    @include respond-to('medium') {
        display: flex;
        position: unset;
        height: 32px;
        overflow-y: unset;
        padding: 0;
        border-bottom: 1px solid $white;

        .wrapper {
            width: calc(100% - 15px);
            padding: 0;
            display: flex;
            align-items: center;

            .mobileCurrencySelector {
                display: none;
            }

            .tradeSignIn,
            .tradeSignup {
                display: none;
            }

            .menuItems {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                padding-bottom: 0;
                height: 100%;
                column-gap: 4px;
                .menuItem {
                    position: relative;
                    border-bottom: none;
                    border-right: 1px solid $white;
                    height: calc(100% - 4px);
                    min-width: auto;

                    > *:first-child {
                        font-size: 16px;
                        padding: 0 5px;
                        column-gap: 6px;
                        > svg {
                            transform: rotate(0);
                            height: 8px;
                            width: 8px;
                            padding: 0;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }

                .link {
                    order: 1;
                    border-right: none;

                    &.home {
                        display: none;
                    }
                    &.favourites span,
                    &.myAccount span {
                        display: none;
                    }

                    .icon {
                        margin-left: 3px;
                        stroke: none !important;
                        height: 17px !important;
                        width: 17px !important;
                    }
                }
            }

            li.enquiryNumbers {
                display: flex;
                margin-left: 22px;
                margin-top: 12px;
                @media (min-width: 620px) {
                    display: none;
                }
            }
        }
    }

    @include respond-to('large') {
        .wrapper {
            .currencySelector {
                display: flex;
                margin: 2px 0 2px 4px;
                height: calc(100% - 8px);
            }

            .menuItems {
                column-gap: 8px;

                .menuItem {
                    > *:first-child {
                        column-gap: 8px;
                        > svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                .link {
                    order: 1;
                    border-right: none;

                    &.favourites span,
                    &.myAccount span {
                        display: initial;
                    }
                }
            }
        }
    }
}
