@import '../../../../../styles/variables.scss';

.menuItem {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    column-gap: 20px;
    border: 1px solid #fff;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;

    .icon {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
    }

    .children {
        flex: 1 1 auto;
        text-align: left;
        font-size: 1.125rem;
        font-weight: 500;
        color: $color-title-text;
        line-height: 1.1;
    }

    .chevron {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
        transform: rotate(-90deg);
    }

    &:hover {
        background-color: $color-background;
    }
}
