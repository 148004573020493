@import '../../../../styles/variables.scss';

.subMenu {
    position: fixed;
    box-sizing: border-box;
    background: white;
    width: calc(100% - 10px);
    height: calc(100vh - 70px);
    top: 70px;
    right: -100%;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    transition: right ease-in-out 0.2s;
    z-index: 201;

    .items {
        list-style: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    &.saleBannerEnabled {
        top: 110px;
    }

    .subMenu {
        z-index: 202;

        @include respond-to('medium') {
            left: 100%;
            top: -1px;
            width: 450px;
            height: calc(100% + 2px);

            .items {
                height: 100%;
                padding-top: 40px;
                padding-bottom: 5px;
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-content: flex-start;

                .link {
                    order: 2;
                    height: initial;
                    min-height: 24px;
                    padding: 3px;
                    border: none;
                    min-width: initial;
                    justify-content: stretch;
                    max-width: 50%;
                    display: flex;
                    flex-wrap: wrap;

                    > *:first-child {
                        white-space: normal;
                    }
                }

                .backButton {
                    order: 1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 30%;
                    max-width: 30% !important;

                    > *:first-child {
                        cursor: pointer;
                        width: initial;
                        color: $white;
                        white-space: nowrap;
                    }
                }

                .parent {
                    position: absolute;
                    top: 0;
                    height: 40px;
                    order: 0;
                    width: initial;
                    max-width: initial;
                    margin: 0;
                    background-color: $tap-primary-color;
                    opacity: 0.85;
                    width: 70%;
                    max-width: 70% !important;

                    > *:first-child {
                        color: $white;
                        white-space: nowrap;
                        width: initial;
                    }
                }
            }

            &.col-3 .items .link {
                max-width: 33%;
            }

            &.col-4 .items .link {
                max-width: 25%;
            }

            &.saleBannerEnabled {
                top: -1px;
            }
        }
    }

    .link:last-child {
        margin-bottom: 200px;
        @include respond-to('medium') {
            margin-bottom: 0;
        }
    }

    .backButton {
        width: 100%;
        border-bottom: none;
        background-color: $tap-primary-color;
        opacity: 0.85;

        button {
            color: $white;
            padding-left: 15px;
            .icon {
                transform: rotate(90deg);
                padding: 5px;
                height: 30px;
                width: 30px;
                color: $white;
                fill: $white;
                stroke: $white;
            }
        }

        @include respond-to('medium') {
            display: none;
            cursor: pointer;
        }
    }

    .parent {
        a {
            font-size: 22px;
            font-weight: 500;
            @include respond-to('medium') {
                font-size: 16px;
            }
        }
    }

    &.isOpen {
        right: 0;

        @include respond-to('medium') {
            right: initial;
            display: flex;
        }
    }

    @include respond-to('medium') {
        display: none;
        width: auto;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: visible;
        height: auto;

        &.saleBannerEnabled {
            top: 100%;
        }
    }
}
