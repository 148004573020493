@import '../../../../../../../styles/variables.scss';

.menuItem {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px 4px 16px;
    border: none;
    border-radius: 10px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;

    > span {
        text-align: left;
        flex: 1 1 auto;
    }

    > svg {
        display: flex;
        align-items: center;
        flex: 0 0 20px;
        height: 20px;
        width: 20px;
        fill: $color-strokes-strong;
        transform: rotate(-90deg);
    }

    &:hover {
        background-color: $color-background;
    }

    &.isLink {
        text-decoration: underline;

        > svg {
            display: none;
        }

        &:hover {
            text-decoration-thickness: 2px;
        }
    }

    &.isSelected {
        background-color: $color-secondary-action;
        background-color: $color-brand;
        color: #fff;
        cursor: default;

        > svg {
            fill: #fff;
        }

        &:hover {
            text-decoration-thickness: 1px;
        }
    }
}

a.menuItem:hover {
    text-decoration: underline;
}
