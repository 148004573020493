@import '../../../../styles/variables.scss';

.blueButton {
    background-color: $tap-action-secondary;
    color: $white;
    border: none;
    &:hover {
        background-color: $color-secondary-action-dark;
    }
    &:active {
        background-color: $color-secondary-action-dark;
    }
}
