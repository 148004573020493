@import '../../../../styles/variables.scss';

$border-radius: 8px;

.desktopSearchTool {
    display: none;

    .container {
        position: relative;
        display: flex;
        height: 48px;
        flex: 1 1 calc(100% - 48px);
        max-width: 160px;

        .input {
            display: flex;
            border: solid 1px $light-grey;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            border-right: none;
            padding-left: 16px;
            outline: none;
            min-width: 120px;
            max-width: 160px;
            transition: border-color linear 0.1s;
            color: $bodytext-black;
            background-color: white;
            width: 100%;
            justify-content: flex-start;

            &:hover {
                border-color: $bodytext-darkgrey;
            }
            &:focus {
                border-color: $tap-action-secondary;
            }
            &::placeholder {
                color: $placeholdertext-grey;
            }
        }

        .suggestionsContainer {
            display: none;
            top: 48px;
            z-index: 200;
            position: absolute;
            flex-flow: column nowrap;
            overflow-y: auto;
            max-height: 350px;
            background-color: $white;
            min-width: 250px;
            border-radius: 0 4px 4px 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            .sectionContainer {
                width: 100%;

                .sectionTitle h5 {
                    margin: 0;
                    padding: 15px;
                    font-size: 1rem;
                }

                .suggestionsList {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: flex-start;

                    .suggestion {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            background-color: $color-secondary-action-light;
                        }
                    }

                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }

        .suggestionsContainerOpen {
            display: flex;
        }
    }

    .searchButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
        border: none;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        background-color: $tap-action-primary;
        cursor: pointer;

        > svg {
            fill: #fff;
            height: 24px;
            width: 24px;
        }

        &:hover {
            background-color: lighten($tap-action-primary, 3);
        }
        &:active {
            background-color: darken($tap-action-primary, 3);
        }
    }

    @include respond-to('medium') {
        display: flex;
    }
}
