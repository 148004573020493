@import '../../../../../styles/variables.scss';

.currencySelector {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    position: relative;
    height: 48px;
    padding: 12px;
    border: 1px solid $color-strokes;
    border-radius: 4px;
    column-gap: 12px;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        right: 12px;
        top: calc(50% - 5px);
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 10px 6px 0 6px;
        border-color: $color-title-text transparent transparent transparent;
    }

    > img {
        flex: 0 0 24px;
        height: 24px;
    }

    .name {
        color: $color-title-text;
    }

    &:hover {
        border-color: $color-strokes-strong;
    }
    &:focus {
        border-color: $color-secondary-action;
    }
}

.popup {
    display: flex;
    padding: 8px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 0.1s linear;
    border: 1px solid $color-strokes;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0px 16px 32px -4px rgba(62, 52, 69, 0.1),
        0px 2px 4px 0px rgba(62, 52, 69, 0.25);
    row-gap: 8px;
    width: 100%;
    max-width: calc(100vw - 80px);

    .option {
        width: 100%;
    }

    &[data-status='open'] {
        opacity: 1;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        opacity: 0;
    }
}
