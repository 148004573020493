@import '../../../../styles/variables.scss';

.primaryButton {
    background-color: $tap-action-primary;
    color: $white;
    border: none;
    column-gap: 10px;

    &:hover {
        background-color: lighten($tap-action-primary, 3);
    }
    &:active {
        background-color: darken($tap-action-primary, 3);
    }
    span {
        color: $white;
        border: none;
    }
}
