// NOTE: This is using the approach and code from the following article
// https://www.sitepoint.com/managing-responsive-breakpoints-sass/

// When updating this you should also update the breakpoints in useWindowWidth.js
$breakpoints: (
    'xsmall': 400px,
    'small': 768px,
    'medium': 1025px,
    'large': 1200px,
);

@mixin respond-to($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @if $value != null {
        @media (min-width: $value) {
            @content;
        }
    } @else {
        @warn "Breakpoint `#{$breakpoint}` could not be found. "
          + "Please make sure it is defined in `$breakpoints` map.";
    }
}
