@import '../../../../styles/variables.scss';

.currencySelector {
    position: relative;
    box-sizing: border-box;
    height: 100%;

    .selectCurrencyButton {
        display: flex;
        width: 64px;
        border: none;
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;
        background-color: $white;
        transition: background-color 0.1s linear;
        padding: 0 3px;
        font-weight: 500;
        font-size: 20px;
        align-items: center;
        cursor: pointer;

        .flag {
            max-height: 26px;
            flex: 0;
            padding-right: 2px;
        }
        .symbol {
            padding-right: 2px;
            text-align: center;
            flex: 1;
        }

        .chevron {
            flex: 0 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 4px 0 4px;
            border-color: $tap-primary-color transparent transparent transparent;
            transition: transform 0.1s ease-in-out;
            &.isOpen {
                transform: rotate(180deg);
            }
        }

        &.isLongSymbol {
            font-size: 12px;
            .flag {
                max-height: 20px;
            }
        }

        &:hover {
            background-color: #eee;
        }
    }

    .currencyMenu {
        border: 1px solid #c4d2d0;
        position: absolute;
        list-style: none;
        right: 0;
        top: 11px;
        background-color: $background-green;
        padding: 0;
        .currencyMenuItem {
            border-bottom: 1px solid $white;
            width: 245px;
            padding: 0;

            > * {
                width: 100%;
                border: 0px;
                outline: none;
                padding: 10px;
                display: flex;
                flex-direction: row;
                background-color: $background-green;
                box-sizing: border-box;
                cursor: pointer;

                .flag {
                    width: 30px;
                    height: 38px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin: -10px 0 -10px;
                }

                .currencySymbol {
                    width: 35px;
                    font-size: 12px;
                    padding: 3px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    text-align: center;
                }
                .currencyName {
                    flex-grow: 1;
                    flex-shrink: 1;
                    text-align: left;
                }

                &:hover {
                    width: 100%;
                    background-color: $tap-primary-color;
                    overflow: hidden;
                    color: $white;
                    img {
                        opacity: 1;
                    }
                }
            }

            &.selected > * {
                background-color: $tap-primary-color;
                color: $white;
                cursor: default;
            }
        }
    }
}
