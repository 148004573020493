@import '../../../../styles/variables.scss';

.redWireButton {
    background-color: $white;
    color: $action-red;
    border-color: $action-red;
    &:hover {
        background-color: $very-light-grey;
    }
    &:active {
        background-color: $background-red;
    }
}
