@import 'mixins/media';

$primary-color: #406365;
$secondary-color-1: #f6f6f4;
$secondary-color-2: #edf7f8;
$action-color-main: #fe696d;
$action-color-secondary: #3cafb4;
$action-color-tertiary: #558718;
$display-color: #f9c61b;
$neutral-color-1: #4a4a4a;
$neutral-color-2: #808080;
$wireframe-color-1: #82cccd;
$wireframe-color-2: #cae9eb;
$wireframe-color-3: #dbf0f1;
$wireframe-color-4: #edf7f8;
$wireframe-color-5: #f3f8fb;

$tap-primary-color: #3e2259;
$tap-action-secondary: #3bafb3;
$action-red: #e45954;
$amber-warning: #ff9901;
$display-gold: #f9c61b;
$feefo-yellow: #ffdd00;
$bodytext-black: #4a4a4a;
$bodytext-darkgrey: #808080;
$bodytext-lightgrey: #9b9b9b;
$placeholdertext-grey: #a2a2a2;
$light-grey: #c8c8c8;
$disabled-grey: #dfdfdf;
$very-light-grey: #fafafa;
$divider-grey: #eee;
$divider-grey-color-2: #ddd;
$black-50: rgba(0, 0, 0, 0.5);
$black-30: rgba(0, 0, 0, 0.3);
$background-blue: #edf7f8;
$background-green: #f6f7f5;
$background-red: #fff4f4;
$white: #ffffff;
$border-dark: #979797;

$tap-action-secondary-disabled: #a2afaf;
$tap-action-secondary-hover: #4cc0c4;

$warning-red: #ff0000;

$tap-green: #406465;
$tap-primary-color: #3e2259;
$tap-action-primary: #fb2f58;
$tap-action-secondary: #20b7e9;
$tap-action-tertiary: #12c7c6;

$positive-green: #548718;
$positive-green-light: lighten($positive-green, 67);
$positive-green-dark: darken($positive-green, 10);

// From Design System - use these in the future

$color-brand: #3e2259;
$color-primary-action: #fb2f58;
$color-secondary-action: #20b7e9;
$color-logo: #356666;

$color-brand-light: #f3e5f5;
$color-secondary-action-light: #c0e6f7;

$color-brand-dark: #301a44;
$color-secondary-action-dark: #098dc0;

$color-strokes: #e6e6e9;
$color-strokes-dark: #e1e1e1;
$color-strokes-strong: #8186a3;
$color-background: #f5f5f5;
$color-background-brand: #f5f6f9;

$color-mid-grey: #b0babf;

$color-background-disabled: #eee;
$color-strokes-disabled: #aeadad;

$color-success: #548718;
$color-error: #ff0000;
$color-warning: #ff9901;

$color-body-text: #4a4a68;
$color-title-text: #18181b;
$color-faint-text: #8592a6;
$color-faint-brand-text: #6e71a2;

$color-reviews: #fad542;

$color-active-background: #f1fafe;

// Buttons
$color-primary-hover: #e9154a;
$color-primary-active: #c50b3f;
$color-primary-disabled: #ffe3e6;
$color-primary-disbaled-text: #ff6c85;

$color-secondary-hover: #098dc0;
$color-secondary-active: #09709b;
$color-secondary-disabled: #e1f4fd;
$color-secondary-disbaled-text: #3fc5f1;

// CTA
$color-cta-foreground-dark: #104f6a;
$color-cta-background-light: #e1f4fd;
