@import '../../../../styles/variables.scss';

.whiteWireButton {
    background-color: transparent;
    color: white;
    border-color: white;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}
