@import '../../../../styles/variables.scss';

.mobileMenuButton {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .menuIcon {
        color: $tap-primary-color;
        height: 100%;
        width: 100%;
    }

    .closeIcon {
        color: $tap-primary-color;
        height: 100%;
        width: 100%;
    }
}
