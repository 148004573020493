@import '../../../styles/variables.scss';

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    box-sizing: border-box;

    @include respond-to('small') {
        border-bottom: none;
    }

    &.isCheckoutPage {
        @include respond-to('small') {
            border-bottom: 1px solid $tap-primary-color;
            .headerMenu {
                display: none;
            }
        }
    }
}
