@import '../../../../styles/variables.scss';

.blueIconButton {
    background-color: $tap-action-secondary;
    color: $white;
    border: none;
    &:hover {
        background-color: #5ebdc0;
    }
    &:active {
        background-color: #3bafb3;
    }
}
