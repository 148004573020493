@import '../../../../styles/variables.scss';

.menuItem {
    height: 50px;
    box-sizing: border-box;
    padding: 0;
    transition: background-color 0.1s linear;

    @include respond-to('small') {
        height: 40px;
        min-width: 150px;
        &:hover {
            background-color: #ededed;
        }
    }

    > *:first-child {
        height: 100%;
        border: none;
        box-sizing: border-box;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        outline: none;
        color: $bodytext-black;
        font-size: 18px;
        background-color: transparent;

        @include respond-to('small') {
            font-size: 16px;
            padding: 0 10px;
            white-space: pre;
        }
    }
}
