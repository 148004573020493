@import '../../../../styles/variables.scss';

.desktopLinks {
    display: none;
    padding: 0;
    margin: 0;
    flex-flow: row nowrap;
    align-items: center;
    list-style: none;

    .link {
        &::after {
            content: '';
            display: flex;
            width: 2px;
            height: 16px;
            background: $color-strokes-strong;
            align-self: center;
        }

        &:last-of-type::after {
            display: none;
        }
    }

    @include respond-to('medium') {
        display: flex;
    }
}
