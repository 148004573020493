@import '../../../styles/variables.scss';
.enquiries {
    display: flex;
    width: 100%;
    max-width: 330px;
    div {
        display: flex;
        .enquiryLabel {
            width: 160px;
            text-align: right;
            font-size: 15px;
        }
        .callUs {
            text-decoration: none;
            margin: 0;
            display: flex;
            align-items: center;

            .displayNumber {
                color: $tap-action-secondary;
                font-size: 18px;
                @media (min-width: 768px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 1021px) and (min-width: 620px) {
                    font-size: 22px;
                }
                margin: 0;
                display: flex;
            }
            svg {
                height: 15px;
                width: 13px;
                margin-right: 5px;
                fill: $tap-action-secondary;
                @media only screen and (max-width: 1021px) and (min-width: 620px) {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}
