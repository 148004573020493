@import '../../../../styles/variables.scss';

.headerTop {
    display: flex;
    justify-content: center;
    height: 70px;
    box-sizing: border-box;
    background-color: $white;
    border-bottom: 2px solid $tap-primary-color;
    z-index: 206;
    width: 100%;
    margin: 0 auto;

    .wrapper {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        align-items: center;
        max-width: 1415px;

        .home {
            box-sizing: border-box;
            height: 100%;
            width: 130px;
            background-image: url(../../../../assets/images/the-adventure-people-logo-full.svg);
            text-indent: -1000%;
            background-size: calc(100% - 8px) auto;
            background-repeat: no-repeat;
            background-position-x: 8px;
            background-position-y: center;
            color: transparent;
            flex-grow: 0;
        }

        .simpleSearchTool {
            margin-top: 5px;
            margin-left: 8px;
            height: 30px;
            display: none;

            input {
                width: 120px;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }

            button {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
            .buttonIcon {
                stroke: $white;
                height: 15px;
                width: 15px;
            }
        }

        .link {
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-grow: 0;
        }

        .mobileSearchMenuButton {
            height: 22px;
            margin-left: 8px;
        }

        .rightContent {
            display: flex;
            margin-left: auto;
            height: 22px;
            justify-content: flex-end;
            align-items: center;
            column-gap: 16px;

            .link {
                height: 100%;
            }

            .phoneButton {
                .phoneIcon {
                    fill: #00a980;
                }
            }

            .favouriteButton {
                display: none;
            }

            .myAccount {
                margin: 10px;
                fill: $tap-primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
            }

            .phoneIcon,
            .favouriteIcon,
            .accountIcon {
                height: 100%;
            }
        }

        .mobileMenuButton {
            height: 22px;
            width: 22px;
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    @media (min-width: 360px) {
        .wrapper {
            .home {
                width: 138px;
                background-size: calc(100% - 16px) auto;
                background-position-x: 16px;
            }
            .mobileSearchMenuButton {
                margin-left: 18px;
            }
            .rightContent {
                column-gap: 18px;
                .favouriteButton {
                    display: flex;
                }
            }
        }
    }

    @media (min-width: 375px) {
        .wrapper {
            .mobileSearchMenuButton {
                margin-left: 24px;
            }
        }
    }

    @include respond-to('small') {
        .wrapper {
            margin-left: 10px;

            .home {
                width: 202px;
                background-position-x: 0;
                background-size: 100% auto;
            }

            .mobileSearchMenuButton {
                height: 30px;
                margin-left: 18px;
                order: 2;
            }

            .rightContent {
                .link {
                    height: 30px;
                }
                order: 1;
                .phoneButton {
                    display: none;
                }
            }

            .mobileMenuButton {
                height: 30px;
                width: 30px;
                order: 3;
            }
        }
    }

    @include respond-to('medium') {
        height: 68px;
        border-bottom: none;
        .wrapper {
            display: flex;
            .simpleSearchTool {
                display: flex;
            }
            .mobileMenuButton,
            .mobileSearchMenuButton,
            .rightContent .link {
                display: none;
            }

            .rightContent {
                column-gap: 8px;
                padding-right: 10px;
                .enquiryNumbers {
                    order: 2;
                }
            }
        }
    }

    @include respond-to('large') {
        .link,
        .mobileSearchMenuButton,
        .mobileMenuButton {
            order: unset;
        }
    }
    .enquiryNumbers {
        display: none;
        @media (min-width: 620px) {
            display: flex;
            margin: 0 auto;
        }
    }
}
