@import '../../../../styles/variables.scss';

.redButton {
    background-color: $action-red;
    color: $white;
    border: none;
    &:hover {
        background-color: lighten($action-red, 3);
    }
    &:active {
        background-color: darken($action-red, 3);
    }
}
