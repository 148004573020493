@import '../../../../../styles/variables.scss';

.currencyOption {
    display: flex;
    width: 100%;
    height: 40px;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;
    padding: 4px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: $color-title-text;
    cursor: pointer;

    .flag {
        display: flex;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        overflow: hidden;

        > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &:hover {
        background-color: $color-background;
        border-color: $color-background;
    }

    &:target,
    &:active,
    &:focus-visible {
        border-color: $color-brand;
    }

    &.isSelected {
        background-color: $color-brand;
        border-color: $color-brand;
        color: #fff;
        cursor: default;
    }
}
