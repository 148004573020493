.feefo {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.light {
        p {
            color: white;
        }
    }

    p {
        color: #9b9b9b;
        font-size: 14px;
        text-align: center;
        margin: 5px 0 0 0;
        width: 100%;
    }
}
