.option {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    column-gap: 5px;
    width: 100%;

    span {
        padding: 0;
        margin: 0;
    }

    .icon {
        margin: 5px;
        flex: 0 0 20px;
    }
}
