.transparentButton {
    background: none;
    color: white;
    text-decoration: underline;
    border: none;

    &:hover {
        background: none;
    }

    &:active {
        background: none;
    }

    &:focus {
        outline-style: none;
    }
}
