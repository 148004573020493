@import '../../../../styles/variables.scss';

.disabledIconButton {
    background-color: $light-grey;
    color: $bodytext-darkgrey;
    border: none;
    &:hover {
        background-color: $light-grey;
    }
    &:active {
        background-color: $light-grey;
    }
}
