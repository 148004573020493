@import '../../../../styles/variables.scss';

$mobileHeight: var(--mobile-height);

.mobileMenu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    z-index: 101;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.1s ease-in-out;

    .header {
        display: flex;
        height: $mobileHeight;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-strokes-dark;
        padding-left: 20px;
        padding-right: 20px;
        column-gap: 106px;

        .home {
            display: flex;
            flex: 1 1 auto;
            max-width: 251px;
            text-decoration: none;
        }

        .closeButton {
            margin-left: 10px;
            border: 0;
            padding: 0;

            > svg {
                height: 40px;
                width: 40px;
            }
        }
    }

    .menuGroup {
        display: flex;
        flex-flow: column nowrap;
        width: calc(100% - 80px);
        border-bottom: 1px solid $color-strokes-dark;

        &.links,
        &.user {
            padding-top: 28px;
            padding-bottom: 28px;
        }

        &.contact {
            padding-top: 20px;
            row-gap: 28px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .phoneLink {
        margin-left: auto;
        margin-right: auto;
    }

    .enquireButton {
        font-size: 1.125rem;
        font-weight: 500;
        height: 48px;
    }

    .currencySelector {
        margin-top: 14px;
    }

    &[data-status='open'] {
        left: 0;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        left: -100% !important;
    }
}
