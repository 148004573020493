@import '../../../../styles/variables.scss';

.mobileSearchMenu {
    box-sizing: border-box;
    padding: 10px 10px 10px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 206;
    right: 0;
    left: 0;
    background-color: $tap-primary-color;
    border-bottom: 1px solid $tap-primary-color;
    display: none;
    align-items: center;
    justify-content: stretch;
    transition: top ease-in-out 0.1s;
    overflow: hidden;
    @include respond-to('large') {
        display: none;
    }
    .searchTool {
        flex: 1;
        height: 40px;

        input[type='search'] {
            border: none;
        }

        button {
            min-width: auto;
        }
    }

    .closeButton {
        width: 20px;
        height: 20px;
        flex: 0;
        margin-right: 10px;
        padding: 0;
        outline: none;
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            fill: $white;
            width: 20px;
            height: 20px;
        }
    }

    &.isActive {
        top: 70px;
        display: flex;
    }
}
