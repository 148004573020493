@import '../../../../../styles/variables.scss';

.phoneLink {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 20px;
    color: $color-title-text;
    font-size: 1.125rem;
    font-weight: 500;

    > svg {
        height: 24px;
        width: 24px;
        stroke: $color-title-text;
    }
}
