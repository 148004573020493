@import '../../styles/variables.scss';

.currencySelectButton {
    display: flex;
    cursor: pointer;
    flex-flow: column nowrap;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background-color: $tap-action-primary;
    color: $white;
    border: none;
    border-radius: 4px;
    row-gap: 4px;
    cursor: pointer;
    transition: all 0.15s ease;
    -webkit-appearance: none;

    > label {
        font-size: 0.875rem;
        text-wrap: nowrap;
        line-height: 1.1;
    }
    > span {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.1;
    }

    &:hover {
        background-color: lighten($tap-action-primary, 3);
    }
    &:active {
        background-color: darken($tap-action-primary, 3);
    }
}

.currencyOptions {
    background-color: $color-background;
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    list-style: none;
    padding: 0;
    row-gap: 1px;
    border: 1px solid $color-strokes;

    > li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 8px;
        column-gap: 4px;
        background-color: #fff;
        cursor: pointer;

        .flag {
            height: 26px;
            flex: 0 0 26px;
        }

        .name {
            text-align: left;
            flex: 1 1 auto;
            margin-right: auto;
        }
        .code {
            font-size: 500;
        }

        &:hover {
            background-color: $color-background;
        }
        &.isSelected {
            background-color: $color-secondary-action;
            font-weight: 500;
            cursor: default;
        }
    }
}
