@import '../../styles/variables.scss';

.simpleSearchTool {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;

    .textbox {
        height: 85%;
        box-sizing: border-box;
        width: 50%;
        min-width: 120px;
        justify-self: stretch;
        border: 1px solid $light-grey;
        flex: 1 1;
        outline: none;
        border-radius: 3px 0 0 3px;
        background-color: $white;
        justify-content: flex-start;
        font-size: 16px;
        align-content: center;
        padding: 0 10px;
        -webkit-appearance: none;

        &:hover {
            border-color: $bodytext-darkgrey;
        }
        &:focus,
        &:target {
            border-color: $tap-action-secondary;
        }
        &::placeholder {
            color: $placeholdertext-grey;
        }
    }

    .searchButton {
        min-width: 30px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        height: 85%;
        padding: 10px;
        column-gap: 0;

        flex: 0 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        svg {
            margin: 0;
        }
    }

    &.isInvalid {
        .textbox {
            border-color: $amber-warning;
        }
    }
}
