.transparentIconButton {
    background: none;
    color: white;
    border: 2px solid white;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
