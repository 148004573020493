@import '../../../styles/variables.scss';

.circularIconButton {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid $color-strokes-strong;
    padding: 11px;
    box-shadow: 0px 2px 4px rgba(62, 52, 69, 0.16);
    cursor: pointer;
    background-color: #fff;
    transition: all 0.15s ease;

    > svg {
        display: flex;
        width: 100%;
        height: 100%;
        fill: $color-title-text;
    }

    &:hover {
        background-color: $color-background;
    }

    &:active {
        box-shadow: 0px 2px 2px rgba(62, 52, 69, 0.3);
    }
}
