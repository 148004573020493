@import '../../../../styles/variables.scss';

.brandButton {
    background-color: $color-brand;
    color: $white;
    border: none;
    &:hover {
        background-color: $color-brand-dark;
    }
    &:active {
        background-color: $color-brand-dark;
    }
}
