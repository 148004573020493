@import '../../../../styles/variables.scss';

$offset: var(--offset);

.desktopPhoneButton {
    display: none;

    @include respond-to('small') {
        display: flex;
    }
}

.popover {
    display: none;
    z-index: 101;
    width: 414px;
    padding: 32px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: opacity 0.1s linear;
    border: 1px solid $color-strokes;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0px 16px 32px -4px rgba(62, 52, 69, 0.1),
        0px 2px 4px 0px rgba(62, 52, 69, 0.25);

    .arrow {
        position: absolute;
        display: flex;
        top: calc(-1 * var(--offset));
        width: 0;
        height: 0;
        border-left: var(--offset) solid transparent;
        border-right: var(--offset) solid transparent;
        border-bottom: var(--offset) solid $color-strokes;
        &:before {
            position: relative;
            top: 1px;
            left: calc(2px - var(--offset));

            content: '';
            width: 0;
            height: 0;
            border-left: calc(var(--offset) - 2px) solid transparent;
            border-right: calc(var(--offset) - 2px) solid transparent;
            border-bottom: calc(var(--offset) - 1px) solid #fff;
        }
    }

    .agentList {
        margin-bottom: 32px;
        width: 100%;
        ul {
            align-items: center;
            position: relative;
            li {
                img {
                    height: 100%;
                    width: 100%;
                }
                border-radius: 50%;
                &:nth-child(1),
                &:nth-child(3) {
                    position: absolute;
                    height: 80px;
                    width: 80px;
                    bottom: 0;
                }
                &:nth-child(1) {
                    left: calc(50% - 111px);
                }
                &:nth-child(3) {
                    right: calc(50% - 111px);
                }
                &:nth-child(2) img {
                    height: 100px;
                    width: 100px;
                    z-index: 1;
                    border: 3px solid white;
                }
            }
        }
    }

    > h3 {
        margin: 0 0 8px;
        font-size: 1.25rem;
    }

    > p {
        margin: 0;
    }

    .phoneLink {
        display: flex;
        flex-flow: row nowrap;
        margin-top: 28px;
        color: $color-title-text;
        text-decoration: none;
        column-gap: 8px;

        > svg {
            width: 24px;
            height: 24px;
            fill: $color-title-text;
        }

        > span {
            text-decoration: underline;
            font-size: 1.125rem;
            font-weight: 500;
        }
    }

    .enquireButton {
        height: 48px;
        margin-top: 32px;
        font-size: 1.125rem;
        font-weight: 500;
    }

    &[data-status='open'] {
        opacity: 1;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        opacity: 0;
    }

    @include respond-to('small') {
        display: flex;
    }
}
