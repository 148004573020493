@import '../../../styles/variables.scss';

.socialBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    nav {
        display: flex !important;
    }

    .socialIcon {
        margin: 0 5px;
    }

    .feefo {
        margin-left: 20px;

        img {
            width: 76px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .socialBar {
        justify-content: center;

        nav {
            flex: 1;
            display: flex;
            justify-content: center;
            transform: translateX(40px);
        }

        .feefo {
            margin-left: 0;
        }
    }
}
