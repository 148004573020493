@import '../../../../styles/variables.scss';

.menuLink {
    position: relative;
    .moreIcon {
        margin-left: auto;
        margin-right: -5px;
        transform: rotate(-90deg);
        padding: 5px;
        height: 30px;
        width: 30px;
        stroke: $bodytext-black;

        @include respond-to('small') {
            display: none;
            padding: 10px;
            margin-right: -10px;
        }
    }

    &.hasChildren {
        @include respond-to('small') {
            > * > .moreIcon {
                display: unset;
            }
        }
    }

    @include respond-to('small') {
        position: unset;
    }
}
