@import '../../../../styles/variables.scss';

.secondaryButton {
    background-color: $tap-action-secondary;
    color: $white;
    border: none;
    text-decoration: none;
    &:hover {
        background-color: lighten($tap-action-secondary, 3);
    }
    &:active {
        background-color: darken($tap-action-secondary, 3);
    }

    .icon {
        margin-left: 10px;
    }
}
